
  import { Component, Vue } from 'vue-property-decorator'
  import getApi from '@/api'
  import QuizReview from '../../api/quizReview'
  import moment from 'moment'
  import { UserInfoInterface, QuizReviewInterface, QuizInterface } from '@/interface/BaseInterface'
  import { size } from 'lodash'
  import QuizApi from '../../api/quizApi'

  const quizReview: QuizReview = getApi('quizReview')
  const quizApi: QuizApi = getApi('quiz')

  @Component({ components: {} })
  export default class QuizReviewReport extends Vue {
    value: Date[] = [
      new Date(
        moment()
          .startOf('month')
          .format('YYYY-MM-DD hh:mm')
      ),
      new Date(
        moment()
          .endOf('month')
          .format('YYYY-MM-DD hh:mm')
      )
    ]

    teachers: UserInfoInterface[] = []

    teacherSelect = ''
    quizSelect = ''

    quizes: QuizInterface[] = []

    isLoading = false
    isAdminLoading = false

    loading = false

    data: QuizReviewInterface[] = []

    get isAdmin() {
      return this.checkRole(['admin'], 'COURSE_DETAIL')
    }

    async init() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        if (this.isAdmin) {
          if (this.teacherSelect) {
            this.data = await quizReview.getUserReport(this.courseId, this.teacherSelect, this.value[0], this.value[1], this.quizSelect)
          } else {
            this.data = []
          }
        } else {
          this.data = await quizReview.getMyReport(this.courseId, this.value[0], this.value[1], this.quizSelect)
        }
        this.quizes = await quizApi.all({
          type: `writing,speaking`,
          courseId: this.courseId
        })
        this.isLoading = false
      } catch (error) {
        this.data = []
        this.isLoading = false
      }
    }

    async fetchDataAdmin() {
      try {
        this.isAdminLoading = true
        this.teachers = await quizReview.listTeachers(this.courseId)
        if (size(this.teachers) > 0) {
          this.teacherSelect = this.teachers[0]._id
        }
        this.isAdminLoading = false
      } catch (error) {
        this.isAdminLoading = false
      }
    }

    handleViewAttempt(data: QuizReviewInterface) {
      this.$router.push({
        name: 'quizResultByUser',
        params: { id: this.courseId, quizId: data.quizId, attemptId: data.attemptId, userId: data.studentId }
      })
    }

    handleTeacherChange() {
      this.init()
    }

    handleQuizChange() {
      this.init()
    }

    handleChange() {
      this.init()
    }

    async created() {
      if (this.isAdmin) {
        await this.fetchDataAdmin()
      }
      await this.init()
    }
  }
